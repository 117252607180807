import * as React from "react";
import Layout from "../components/Layout";
import Telescope from '../../static/SVG/SVG/404-telescope.svg';
import {Container, Grid} from "@mui/material";
import {css as styled} from "@emotion/react";
import {useTheme} from "@mui/material/styles";


const styles = theme => styled`
	.wrapper {
		margin: 140px auto;
		
		.container {
			justify-content: center;
			display: flex;
		}
		
		h1 {
			font-size: 200px;
			line-height: 70px;
			margin-bottom: 10px;
		}
		h2 {
			font-size: 47px;
		}
		img {
			max-height: 400px;
			margin-top: 50px;
		}
	}
`;


const NotFoundPage = () => {
	const theme = useTheme()

	return <Layout>
		<div css={styles(theme)}>
			<Container className="wrapper">
				<Grid container className="container">
					<Grid item md={4}>
						<img src={Telescope} alt="404 image"/>
					</Grid>
					<Grid item md={4}>
						<h1>404</h1>
						<h2>Page not found</h2>
						<p>
							The page you are looking for may have moved,<br/>been deleted or possibly never even existed.
						</p>
					</Grid>
				</Grid>
			</Container>
		</div>
	</Layout>;
};

export default NotFoundPage;
